/* General styling for all list items */
.available-sections-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.available-sections-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px; /* Default margin-bottom for all list items */
}

/* Remove margin-bottom for the last list item */
.available-sections-list-item:last-child {
  margin-bottom: 0;
}

/* Styling for the container around selected sections */
.selected-sections-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

/* Styling for DraggableSection container */
.selected-sections-list {
  display: flex;
  flex-direction: column;
}

/* Styling for each DraggableSection (if necessary) */
.selected-sections-item {
  margin-bottom: 8px; /* Default margin for each DraggableSection */
}

/* Remove margin-bottom for the last DraggableSection */
.selected-sections-item:last-child {
  margin-bottom: 0;
}
