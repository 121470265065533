
.Map-Legend {
	background-color: rgba(74,80,86, 0.9);
	color: white;
	padding: 6px 12px;
	font-family: monospace;
	z-index: 0;
	position: absolute;
	bottom: 90px;
	right: 10px;
	margin: 0;
	border-radius: 4px;
	display:flex;
	flex-direction: column;
	min-width: 300px;
}

.Map-Legend-Label {
	order: 0;
	max-width:100%;
}

.Map-Legend-Scale {
	order: 1;
	color:white;
	max-width:100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border: 1px solid black;
}

.Map-Legend-Label-Mobile {
	display: none;
}
.Map-Legend-Mobile {
	width:100%;
	padding:0;
}

.Map-Legend-Item {
	padding:8px;
	border-right: 1px solid black;
}

.Map-Legend-Item-Final {
	border-right: none;
}

.Map-Legend-Item-Black-Text {
	color:black;
}