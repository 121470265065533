/* .mapboxgl-popup-content {
	padding:0;
	min-width: 251px;
} */

.Map-LayerDataPopup-Title {
	font-weight: bold;
}

.Map-LayerDataPopup-Metadata {
	padding:8px 16px 0 8px
}

.Map-LayerDataPopup-controls-wrap {
	border-top: 1px solid gray;
	padding:10px 10px 10px;
}

.Map-LayerDataPopup-controls-inner {
	display:flex;
	flex-direction: row;
}

.Map-LayerDataPopup-Metdatadata-td {
	width:50%;
	padding:0;
}
.Map-LayerDataPopup-Metdatadata-td-key-input {
	width:100%;
	padding:0;
	margin-right: 4px;
}
.Map-LayerDataPopup-Metdatadata-td-val-input {
	width:100%;
	padding:0;
	margin-left: 4px;
}
.Map-LayerDataPopup-Metdatadata-tr-odd {
}


.Map-LayerDataPopup-Metadata-controls {
	margin: 8px;
	display: flex;
	flex-direction: row;
}
.Map-LayerDataPopup-Metadata-controls-add-row-wrap {
	order:0;
	width:50%;
}
.Map-LayerDataPopup-Metadata-controls-style-wrap {
	order:1;
	width:50%;
	display: flex;
	justify-content: center;
}

.Map-LayerDataPopup-Metadata-controls-add-row-button {
	color:#2980b9;
	padding: 0;
	border: none;
	background: none;
	cursor:pointer;
	font-weight: bold;
}





.Map-LayerDataPopup-controls-save-and-cancel-wrap {
	order:0;
	width:50%;
	display:flex;
}

.Map-LayerDataPopup-controls-save-inner {
	order:0;
	width:50%;
}

.Map-LayerDataPopup-controls-cancel-inner {
	order:1;
	width:50%;
}

.Map-LayerDataPopup-controls-delete-wrap {
	order:1;
	width:50%;
	padding:0 0 0 16px;
}

.Map-LayerDataPopup-controls-save-button {
	cursor:pointer;
	border-radius: 3px 3px 3px 3px;
	padding:5px;
	background: #eee;
	margin:0;
	border:none;
	box-sizing: border-box;
	background: #34495e;
	color:white;
	width:100%;
}
.Map-LayerDataPopup-controls-save-button:hover {
	background:rgb(66,127,180);
}

.Map-LayerDataPopup-controls-cancel-button {
	cursor:pointer;
	border-radius: 3px 3px 3px 3px;
	padding:5px;
	background: #eee;
	margin:0;
	border:none;
	box-sizing: border-box;
	width:100%;
}
.Map-LayerDataPopup-controls-cancel-button:hover {
	background:lightgray;
}

.Map-LayerDataPopup-controls-delete-button {
	color:#b81b1b;
	padding: 0;
	border: none;
	background: none;
	cursor:pointer;
	font-weight: bold;
	margin:5px 0 0 0;
}







.Map-LayerDataPopup-Metadata-controls-style-button {
	color:#2980b9;
	padding: 0;
	border: none;
	background: none;
	cursor:pointer;
	font-weight: bold;
}
.Map-LayerDataPopup-Style-Panel {
	border-top:1px solid gray;
	max-height: 50px;
	height:50pxpx;
	max-width: 100%;
	width:100%;
}

.hue-picker {
	max-width: 100%;
}







