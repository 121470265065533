.EventIntersectPowerOutage {
	margin: 32px 0 32px 0;
}

.EventIntersectPowerOutage-Title-Text {
	font-weight: bold;
}
.EventIntersectPowerOutage-Model-Value {
	width: 100%;
	display: flex;
}

.EventIntersectPowerOutage-Data {
	margin: 0px;
}
