.notificationLog-pdfPreview {
  background-color: white;
  color: #000 !important ;
}

.notificationLog-pdfPreview * {
  color: #000 !important;
}

.notificationLog-pdfPreview h5 {
  color: black;
}

.notificationLog-pdfPreview .notificationLog-rowDetail{
  color: black;
  background-color: #cfcfcf;
}