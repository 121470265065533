
.task-bg-main {
    background: #343A40;
    padding: 20px;
}

.graph-label-box {
    width: 12px;
    height: 12px;
    margin-right: 8px;
}

.task-list {
    gap: 10px;
    overflow: auto;
    height: calc(100vh - 400px);
}
.tasklist-card {
    box-shadow: 0px 0px 11px 3px #00000033;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 12px;
    align-items: center;
}
.task-date {
    margin-right: 35px;
}
.task-status-badge {
    width: 140px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 5px;
    font-size: 12px;
    
}
.task-status-badge-circle {
    width: 20px;
    height: 20px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    font-size: 12px;
    background: #ffffff;
}
.task-status-badge-circle-bar {
    width: 2px;
    height: 100%;
    background-color: #6C757D;
    position: absolute;
    right: 165px;
    top: 20px;
}
.task-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 120px);
}
.task-card-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
}
.task-card-content-three-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.9em;
    width: calc(100% - 30px);
}

.task-btn-all {
    height: 48px;
    width: 120px;
    margin-top: auto;
    margin-left: 20px;
    margin-right: 30px;
}
.task-time-refresh-btn {
    height: 48px;
    width: 48px;
    margin-top: auto;
}
.task-time-refresh-btn:hover {
    background: #008ae6 !important;
    color: #ffffff !important;
}
.refresh-icon {
    height: 1.5rem !important;
    width: auto !important;
    transform: rotate(-90deg);
}
.task-filter-container {
    height: 48px;
    margin-top: auto;
    margin-left: auto;
    display: flex;
    padding-right: 40px;
}

.task-filter-options {
    width: 200px;
} 
.task-card-right-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}
.task-card {
    width: 100%;
    height: 96px;
    border-radius: 5px;
    padding: 15px 20px;
    min-width: 350px;
}
.task-team-name-container {
    width: 110px;
    height: 28px;
    background: #00000085;
    font-size: 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-person-name {
    background: #00000085;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-team-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 10px);
}

/* task map */
.scroll-tasks {
    max-height: calc(100% - 480px);
}
.task-map-card {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: solid 2px #6c757d;
}

.task-popup-container {
    cursor: pointer;
}
.task-popup-container:hover {
    background-color: lightgray;
    font-weight: bold;
}

/* task form */
.task-form-height {
    height: 48px;
}

.task-stability {
    height: 48px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.filter-btn {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 0;
}

.filter-btn img {
    width: 48px;
    height: 48px;
}