.event-detail-container {
    max-height: calc(100% - 70px);
    overflow-y: auto;
}

.EventDetailsHurricaneWindSpeedCell {
    height:100%;
    width:100%;
}

.nonSelectableRow {
    background-color: #212529;
}