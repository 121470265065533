/* SitrepSection.css */

.text-section {
  overflow-x: auto;
}

.custom-markdown-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.custom-markdown-th,
.custom-markdown-td {
  border: 1px solid #dee2e6;
  background-color: #262626;
  padding: 0.75rem;
  vertical-align: top;
  text-align: left;
}

.custom-markdown-th {
  background-color: #454545;
  font-weight: bold;
}

.custom-markdown-tr:nth-child(even) {
  background-color: #262626;
}
