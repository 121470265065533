.PowerOutage-Table-Wrap {
	height:100%;
}

.PowerOutageChart-tooltip {
	background-color: #343a40;
	opacity: .4;
	z-index: 99999999;
}

.PowerOutage-tooltip-item {
	
}