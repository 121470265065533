.custom-accordion {
  .accordion-button {
    text-transform: unset;
  }

  .accordion-button:before {
    box-sizing: border-box;
    content: unset;
  }

  .accordion-button:after {
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: inherit;
    line-height: inherit;
    display: inline-block;
    vertical-align: top;
    flex-shrink: 0;
    font-size: 20px;
    margin-right: 0;
    margin-top: -1px;
    background-image: none;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
  }

  .accordion-header {
    border-bottom: 1px solid #6c757d;
  }

  .accordion-item:last-child {
    .accordion-header {
      border-bottom: 0;
    }

    .accordion-body {
      border-bottom: 0;
    }

    .show {
      border-top: 1px solid #6c757d;
      overflow: hidden;
    }
  }

  .accordion-body {
    border-bottom: 1px solid #6c757d;
  }
}
