.adminGroups-items {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border:none;
}

.adminGroups-page-header {
  margin: 0 20px 0 20px;
}

.groups-user-email-address {
  margin:  0 0 0 20px;
}

.adminGroups-section-header {
}

@media (max-width: 767.98px) {
  .adminGroups-section-header {
    margin-bottom: 20px;
  }
}

.adminGroups-actionName {
  margin-left: 20px;
}

.adminGroups-subscript {
  color: #67A8FE;
  font-style: italic;
}

.upgrade-prompt {
}

.adminGroups-section {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border:none;
}

.adminGroups-incidentLink {
  color: #45A4FF;
  cursor: pointer;
}


.groups-user-email-address {
}

.adminGroups-hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #666666;
}

.AddMembers-h3 {
  margin-bottom: 0;
}

.AdminGroups-Input input {
  margin-left: 0;
}

.adminGroups-accordion {
  margin-top: 30px;
}

.adminGroups-accordion .accordion__item .accordion__heading .accordion__button {
  background-color: #333;
  border: 0px;
}

.adminGroups-accordion .accordion__item .accordion__heading .accordion__button:hover {
  background-color: #2f2f2f;
}

.adminGroups-accordion .accordion__item {
  background-color: #404040;
}

.additional-data {
  background-color: #404040;
  padding: 16px;
  padding-top: 24px;
  margin-bottom: 8px;
  position: relative;
}

.additional-data .additional-data-close {
  height: 20px;
  width: auto;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

.additional-data .form-input {
  margin-bottom: 12px;
}

.additional-data .stylishInput {
  width: 100%;
  box-sizing: border-box;
  height: 26px;
}

.additional-data p {
  margin: 0 0 4px;
}

.acceptedValuesWrapper {
  display: flex;
  flex-direction: column;
}

.acceptedValuesWrapper .stylishInput {
  margin: 0;
}

.chip-width {
  width: 100%;
  margin-bottom: 8px;
}

.chip-width .MuiChip-root {
  width: 100%;
}

.chip-width .MuiChip-label {
  flex-grow: 1;
}

.additional-data .StylishButtonIcon {
  margin: 0;
}

.acceptedValuesInput {
  display: flex;
  margin: 8px 0;
}

.acceptedValuesInput .stylishInput {
  width: auto;
  min-width: auto;
  flex-grow: 1;
}

.acceptedValuesInput .StylishButton {
  flex-shrink: 0;
  margin: 0;
  margin-left: 8px;
  width: 26px;
  height: 26px;
  font-size: 16px;
}
.acceptedValuesButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.acceptedValuesButtons .StylishButton {
  margin: 0;
}