.ERI-Table-Wrap {
	height:100%;
}

.ERIChart-tooltip {
	background-color: #343a40;
	opacity: .4;
	z-index: 99999999;
}

.ERI-tooltip-item {
	
}