.react-flow {
	background-color: white;
}

.WorkflowYouLabel {
	font-weight: bold;
}

.FormWorkflow {
	height: 100%;
}