.EventAIAnalysis-paragraph-header {
  margin: 8px 0 8px 0;
}

.ChatDisasterGPT-input-wrap {
  margin: 0 0 32px 0;
}

.ChatDisasterGPT-input-button-wrap {
  margin: 8px 0 0 0;
}

/* Add margin and proper positioning for fullscreen mode */
.w-md-editor-fullscreen {
  position: fixed; /* Ensure it's fixed to the viewport */
  top: 128px; /* Margin from the top */
  left: 128px; /* Margin from the left */
  right: 128px; /* Margin from the right */
  bottom: 128px; /* Margin from the bottom */
  box-sizing: border-box; /* Prevent overflow issues */
  z-index: 999999999 !important; /* Ensure it is on top of all elements */
  max-height: 512px;
}
