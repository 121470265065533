.StylishDateTimePicker-section {
  margin-bottom: 10px;
}

.StylishDateTimePicker-date {
  cursor: pointer;
}

.StylishDateTimePicker-date input {
  color: white;
  cursor: pointer;
  box-sizing: content-box;
}

.StylishDateTimePicker-date .react-date-picker__wrapper {
  border: none;
}

.StylishDateTimePicker-date .react-date-picker__inputGroup {
  min-width: 0px;
  border: 0.1px solid grey;
  background: black;
  border-radius: 5px;
  padding: 3px 7px;
}

.StylishDateTimePicker-time {
  border: 0.1px solid grey;
  background: black;
  border-radius: 5px;
  padding: 6px 7px;
  cursor: pointer;
}

.StylishDateTimePicker-timeSelector {
  display: none;
  position: absolute;
  margin-left: 0px;
  margin-top: -150px;
  z-index: 99;
}

.StylishDateTimePicker-timeSelector--visible {
  display: block;
}

@media (max-width: 991.98px) {
  .StylishDateTimePicker-timeSelector {
    position: relative;
    margin-top: 10px;
  }
}
