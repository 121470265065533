.event-layer-item {
	padding-left: 0 !important;
}

.event-layer-item:hover {
	background-color: lightgray;
	font-weight: bold;
}
.event-layer-item::before {
	content: "" !important;
}

.custom-color {
	display: flex;
	height: 100%;
}