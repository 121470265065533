.oil-spill-info {
  display: flex;
  flex-direction: column; /* Stack spans vertically */
  align-items: flex-end; /* Right-align the content */
}

.oil-spill-info span {
  margin-bottom: 0.5rem; /* Add some space between each span */
  text-align: left; /* Right-align the text within each span */
  width: 100%; /* Make each span take the full width */
}
