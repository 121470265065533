/* Post Map */
.post-map {
    box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
}

.post-map-container .map-container {
    height: 700px;
    position: relative;
}

.twitter-post {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 120px;
}

.slick-slide {
    padding: 0 5px;
}