.EditDistributionListDialog {
  min-height: none;
}

.EditDistributionListDialog .notificationComposeScreen-section {
  background: #3f3f3f;
}

.EditDistributionListDialog .dialogBody {
  height: auto;
}

.EditDistributionListDialog-section {
  margin-bottom: 40px;
}

.EditDistributionListDialog-input {
  margin-left: -3px;
  width: 100%;
  margin-top: 0px;
}