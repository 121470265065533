.Remove-Item-Button {
	margin-right: 10px;
}

.NotificationRecipientRow input {
	min-width: 200px;
}

@media (max-width: 767.98px) {
	.NotificationRecipientRow input {
		min-width: auto;
		flex-grow: 1;
		flex-shrink: 0;
		width: auto;
	}
}

.NotificationRecipientRow .SubSection5px > span {
	display: flex;
	align-items: center;
}

.NotificationRecipientsWrapper {
	display: flex;
	align-items: flex-end;
}

.NotificationRecipientsWrapper .StylishButton {
	margin-bottom: 0;
}

.NotificationRecipientsWrapper .ValidationMessages {
	margin-top: 0;
}

.NotificationRecipientsWrapper .stylishInput {
	margin-right: 10px;
}

.NotificationRecipientRowWrapper {
	display: flex;
	flex-direction: column;
}

.StylishButtonIcon {
	width: 35px;
	height: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.StylishButtonIcon * {
	flex-shrink: 0;
}