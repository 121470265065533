.stylishCheckbox-label input[type="checkbox"] + span::before
{
    content: "";
    width: 12px;
    height: 12px;
    margin: -2px 6px 0 0;
    border: solid 0px #787878;
    line-height: 14px;
    text-align: center;

    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;

    background: #1a1a1a;
}

.stylishCheckbox-label {
    position: relative;
}

.stylishCheckbox-label input[type="checkbox"]
{
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.stylishCheckbox-label input[type="checkbox"]:checked + span::before
{
    content: "\2714";
    font-size: 12px;
    vertical-align: middle;

    color: #000;
    border: none;
    background: #FFFFFF;
}

.stylishCheckbox-label input[type="checkbox"]:disabled + span
{
    cursor: default;
     
    -moz-opacity: .4;
    -webkit-opacity: .4;
    opacity: .4;
}

.stylishCheckbox-label:hover span::before,
.stylishCheckbox-label:hover span::before
{
    -moz-box-shadow: 0 0 2px #fff;
    -webkit-box-shadow: 0 0 2px #fff;
    box-shadow: 0 0 2px #fff;
}
 
.stylishCheckbox-label:hover span,
.stylishCheckbox-label:hover span
{
    color: #fff;
}
