.dialogModal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #1f1f1faa;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y:scroll;
}

.dialogContent {
  text-align: left;
  background-color: #2f2f2f;
  min-height: 100px;
  width: 800px;
  max-width: 90%;
  box-sizing: border-box;
  padding: 20px;
  border-color: #b2b2b2;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  overflow-y:auto;
  /* height:90%; */
  max-height: 90%;
  margin: 5% 0;
  z-index: 11;
}

.dialogBody {
  margin-bottom: 8px;
}

.dialogBody  .mapboxgl-ctrl-icon {
}

.dialogFooter {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top:  32px;
}

.dialogFooter > *:first-child:not(.StylishButton) {
    margin-right: 5px;
}

.dialogHeader {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.dialog-form-input-title {
  margin: 8px 0px 8px 0;
  font-weight: bold;
}

.dialog-form-input-subtitle {
  margin: 0px 0px 0px 0;
}

.dialog-form-input {
  margin: 0px 0 8px 0;
}

.dialog-form-input h3,h4 {
  margin-top: 16px;
  margin-bottom: 8px;
}

.dialog-form-input input[type="checkbox"] {
  display:  none;
}

/* .dialog-form-input input {
  width: 400px;
} */

@media (max-width: 767.98px) {
  .dialog-form-input input {
    width: 100%;
    box-sizing: border-box;
  }
}


.dialogExpanded {
  width: 90%;
}

.dialog-subtitle {
  margin: 0 0 32px 0;
  font-weight: bold;
}
