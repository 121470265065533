.GeoLocationComponent-Details-Title {
	font-style: italic;
}

.GeoLocationComponent-Details-Text {
	font-weight: bold;
}
.GeoLocationComponent-Details-Text-Editable:hover {
	cursor: pointer;
	background-color: lightgray;
	color:black;
	border-radius: 4px;
    padding: 0 8px;
}

.GeoLocationComponent-Map-Wrap {
	width:100%;
	min-height: 400px;
}

.GeoLocationComponent-Map-Container {
	width:100%;
	height:100%;
	min-height: 400px;
}

.mapboxgl-canvas-container{
	width:100%;
	height:100%;
	min-height: 400px;
}

.GeoLocationComponent-Map-Active-Wrap {
	height: 100%;
	width: 100%;
}

.icon-button {
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	width: 29px;
	height: 29px;
	background-color: white;
	border-radius: 3px;
	margin-left: 10px;
	cursor:pointer;
	pointer-events: auto;
	z-index: 999;
}


.GeoLocationComponent-Map-Search {
	margin-top:140px;
}