.Map-ModelLayerPopup-Title-Text {
	font-weight: bold;
}
.Map-ModelLayerPopup-Model-Value {
	font-weight: bold;
}

.Map-ModelLayerPopup-Data {
	margin: 0px;
}

.ModelLayerPopup-Button {
	width:100%;
}