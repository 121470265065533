.Map-PowerOutageLayerPopup-Title-Text {
	font-weight: bold;
}
.Map-PowerOutageLayerPopup-Model-Value {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Map-PowerOutageLayerPopup-Data {
	margin: 0px;
}

.PowerOutageLayerPopup-Button {
	width:100%;
}